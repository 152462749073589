import "./App.css";
import React from "react";
import {createReactOidc} from "oidc-spa/react";
import {z} from "zod";
import ConfigProvider from "antd/es/config-provider";
import {SWRConfig} from "swr";

export const {OidcProvider, useOidc} = createReactOidc({
    issuerUri: process.env.REACT_APP_KEYCLOAK_URL + "/realms/landings",
    clientId: "landings-client",
    publicUrl: process.env.PUBLIC_URL,
    decodedIdTokenSchema: z.object({
        sub: z.string(),
        preferred_username: z.string(),
        resource_access: z.any(),
    }),
});
const AppRoutes = React.lazy(() => import('./Router'))

function App() {

    return (
        <ConfigProvider
            theme={{
                token: {
                    "colorPrimary": "#219c90",
                    "colorInfo": "#219c90",
                    "colorSuccess": "#3bb900",
                    "colorWarning": "#ca8600"
                }
            }}>
            <OidcProvider>
                <SWRConfig value={{provider: () => new Map()}}>
                    <div className="App">
                        <AppRoutes/>
                    </div>
                </SWRConfig>
            </OidcProvider>

        </ConfigProvider>
    );
}

export default App;
